/* eslint-disable @typescript-eslint/no-namespace */
import React from 'react'
import { createRoot } from 'react-dom/client'
import axios, { AxiosError } from 'axios'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { QueryClientProvider, QueryClient, QueryCache } from 'react-query'
import queryString from 'query-string'
import * as Sentry from '@sentry/react'
import { NextUIProvider } from '@nextui-org/react'

import { initializeApp } from 'firebase/app'

import 'react-datetime/css/react-datetime.css'
import './index.css'

import 'services/api/api-interceptors'
import { fireSwalError } from 'utils/error-handler'
import { AuthProvider } from 'context/auth-context'
import { firebaseConfig } from 'config/firebase'
import { App } from './app'

declare global {
  namespace JSX {
    interface ChatlioWidgetAttributes {
      widgetid: string
    }
    interface IntrinsicElements {
      'chatlio-widget': ChatlioWidgetAttributes
    }
  }
}

initializeApp(firebaseConfig)

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.paramsSerializer = (params) => queryString.stringify(params, { arrayFormat: 'none' })

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [],
  tracesSampleRate: 0.5,
  environment: process.env.NODE_ENV ?? 'development',
  enabled: window.location.hostname !== 'localhost',
})

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => fireSwalError(error),
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if ([401, 403, 404].includes((error as AxiosError)?.request?.status)) {
          return false
        }
        return failureCount < 3
      },
    },
  },
})

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <App />
      </QueryParamProvider>
    ),
  },
])

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <NextUIProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </NextUIProvider>
      </QueryClientProvider>
    </React.StrictMode>,
  )
}
