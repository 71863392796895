/* eslint-disable no-console */

export const firebaseConfig: { [key: string]: string | undefined } = process.env
  .REACT_APP_FIREBASE_CONFIG_JSON
  ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_JSON)
  : {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
    }

console.log('*** Environment ***', process.env.REACT_APP_ENV)
console.log('*** Firebase Config ***', firebaseConfig)
