export enum ModuleAccess {
  Analytics = 'b',
  Content = 'c',
  Community = 'y',
  Quest = 'q',
  Settings = 's',
  Account = 'a',
  Subscribers = 'u',
  Design = 'd',
  Messaging = 'n',
}
